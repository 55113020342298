import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upcomming-api',
  templateUrl: './upcomming-api.component.html',
  styleUrls: ['./upcomming-api.component.css']
})
export class UpcommingApiComponent implements OnInit {

  
  constructor(private router: Router) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  apiList = [
    {
      "Title": "Html To Pdf",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Html To Pdf  
    {
      "Title": "Time Zone",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Time Zone    
    {
      "Title": "Movie [IMDB]",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Movie    
    {
      "Title": "Covid-19",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Covid-19    
    {
      "Title": "Youtube To MP3",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Youtube To MP3    
    {
      "Title": "Youtube To MP4",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Youtube To MP4    
    {
      "Title": "Breaking News",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Breaking News    
    {
      "Title": "Barcode Generate",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Barcode    
    {
      "Title": "DNS Lookup",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//DNS Lookup    
    {
      "Title": "Capcha To Text",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Capcha To Text    
    {
      "Title": "Password Generator",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Password Generator    
    {
      "Title": "Valid-Email",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Valid-Email    
    {
      "Title": "Quotation",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Quotation    
    {
      "Title": "QR Code Generator",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//QR Code Generator    
    {
      "Title": "Image Convertor ",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Image Convertor    
    {
      "Title": "Doc To Pdf",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Doc To Pdf     
    {
      "Title": "Url Shortner ",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Url Shortner     
    {
      "Title": "Crypto Exchange Rate",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Crypto Exchange Rate     
    {
      "Title": "IP to City",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//IP to City     
    {
      "Title": "IP Geolocation",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//IP Geolocation     
    {
      "Title": "Screen Shot",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//screenshot      
    {
      "Title": "Encode",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Encode     
    {
      "Title": "Decode",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Decode     
    {
      "Title": "Visitor Count",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Visitor Count     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time     
    // {
    //   "Title": "Time",
    //   "Url": "/live-station",
    //   "ImageUrl": "Mike",
    //   "Rating": "4+",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Time   
    {
      "Title": "IP Address",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "3.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    }//IP Address 
  ];

  ngOnInit() {
  }

}
