import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { UtilityService } from '../services/utility.service';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'contact.component.html',
  styleUrls: ['contact.component.css']
})

export class ContactPageComponent {
    
  constructor(private utility: UtilityService) {
    this.utility.scrollTop();
}



}