import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {

  
 
  constructor(private utility: UtilityService) {
    this.utility.scrollTop();
}


  ngOnInit() {
  }

}
