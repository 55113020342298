import { Component, NgZone, OnInit, AfterViewInit } from '@angular/core';
import { Message, LiveUser } from '../chat/chatmessage';
import { ChatService } from '../chat/chat.service';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'userlist.component.html',
  styleUrls: ['userlist.component.css']
})

export class UserListPageComponent implements OnInit, AfterViewInit {
  title = 'ClientApp';
  txtMessage: string = '';
  uniqueID: string = new Date().getTime().toString();
  messages = new Array<Message>();
  allLiveUser = new Array<LiveUser>();
  allLiveUser2 = new Array<LiveUser>();
  allLiveUser3 = new Array<LiveUser>();
  message = new Message();
  liveUser = new LiveUser();

  heroes = [];
  myHero = this.heroes[0];

  constructor(
    private chatService: ChatService,
    private _ngZone: NgZone
  ) {
    this.subscribeToEvents();
  }

  public ngOnInit() {
    //this.showUserInformation();
  }


  public ngAfterViewInit() {
    //this.showUserInformation2();
  }



  sendMessage(): void {
    if (this.txtMessage) {
      this.message = new Message();
      this.message.clientuniqueid = this.uniqueID;
      this.message.type = "sent";
      this.message.message = this.txtMessage;
      this.message.date = new Date();
      this.messages.push(this.message);
      this.chatService.sendMessage(this.message);
      this.txtMessage = '';
    }
  }

  sendUserInformation(): void {
    this.liveUser = new LiveUser();
    this.liveUser.UniqueID = this.uniqueID;
    this.liveUser.EmailID = "singhkgp@gamil.com";
    this.liveUser.Platform = "WEB";
    this.liveUser.UserName = this.liveUser.EmailID + "-" + this.liveUser.Platform;
    this.allLiveUser.push(this.liveUser);
    this.chatService.getLiveUserList(this.liveUser);
    //this.chatService.getLiveUserList2();
  }

  showUserInformation(): void {
    this.chatService.getLiveUserList1();
    //alert("1" + JSON.stringify(this.allLiveUser));
  }

  showUserInformation2(): void {
    //this.heroes = [{ "uniqueID": "1", "emailID": "1", "userName": "1", "platform": "1" }, { "uniqueID": "2", "emailID": "2", "userName": "2", "platform": "2" }, { "uniqueID": "1592901727783", "emailID": "singhkgp@gamil.com", "userName": "singhkgp@gamil.com-WEB", "platform": "WEB" }];
    this.chatService.getLiveUserList2();
    //alert("2" + JSON.stringify(this.allLiveUser));
  }

  showBindList(data): void {
    this.heroes = data; //[{ "uniqueID": "1", "emailID": "1", "userName": "1", "platform": "1" }, { "uniqueID": "2", "emailID": "2", "userName": "2", "platform": "2" }, { "uniqueID": "1592901727783", "emailID": "singhkgp@gamil.com", "userName": "singhkgp@gamil.com-WEB", "platform": "WEB" }];

  }

  showBindList2(data): void {
    this.heroes = [{ "uniqueID": "1", "emailID": "1", "userName": "1", "platform": "1" }, { "uniqueID": "2", "emailID": "2", "userName": "2", "platform": "2" }, { "uniqueID": "1592901727783", "emailID": "singhkgp@gamil.com", "userName": "singhkgp@gamil.com-WEB", "platform": "WEB" }];

  }

  showBindList3(): void {
    alert("x1  ::  " + JSON.stringify(this.chatService.allLiveUser_service));
    this.allLiveUser2 = this.chatService.allLiveUser_service;
  }

  private subscribeToEvents(): void {

    this.chatService.messageReceived.subscribe((message: Message) => {
      this._ngZone.run(() => {
        if (message.clientuniqueid !== this.uniqueID) {
          message.type = "received";
          this.messages.push(message);
        }
      });
    });

    this.chatService.liveUserList.subscribe((liveUser: LiveUser) => {
      this._ngZone.run(() => {
        if (liveUser.UniqueID !== this.uniqueID) {
          this.allLiveUser.push(liveUser);

          var valText = [{ "uniqueID": "1", "emailID": "1", "userName": "1", "platform": "1" }, { "uniqueID": "2", "emailID": "2", "userName": "2", "platform": "2" }, { "uniqueID": "1592901727783", "emailID": "singhkgp@gamil.com", "userName": "singhkgp@gamil.com-WEB", "platform": "WEB" }];
          var valText2 = this.chatService.allLiveUser_service;
          
          this.showBindList(valText2);
          this.allLiveUser2 = this.chatService.allLiveUser_service;
        }
      });
    });

  }
}
