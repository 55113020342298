import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility.service';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'disclaimer.component.html',
  styleUrls: ['disclaimer.component.css']
})

export class DisclaimerPageComponent {
  constructor(private utility: UtilityService) {
    this.utility.scrollTop();
}




}