import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'all-api.component.html',
  styleUrls: ['all-api.component.css']
})

export class AllApiListComponent {


  constructor(private router: Router) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleClick(link) {
    this.router.navigate(['/api-collection' + link]);
    console.log('Click!', event)
  }


  apiList = [   
    {
      "Title": "Auto Complete Station",
      "Url": "/api-collection/autocomplete-station",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Railway Station
    {
      "Title": "Trains On Station",
      "Url": "/api-collection/all-trains-on-station",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Trains On Station   
    {
      "Title": "Train Information",
      "Url": "/api-collection/train-no-information",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Train Information 
    {
      "Title": "Train Schedule",
      "Url": "/api-collection/train-schedule",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Train Schedule   
    {
      "Title": "Train Between Station",
      "Url": "/api-collection/trains-between-station",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Train Between Station  
    {
      "Title": "Running Train Status",
      "Url": "/api-collection/live-train-status",
      "ImageUrl": "Mike",
      "Rating": "4.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Running Train Status 
    {
      "Title": "Special Trains",
      "Url": "/api-collection/special-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Special Trains   
    {
      "Title": "Heritage Trains",
      "Url": "/api-collection/heritage-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Heritage Trains   
    {
      "Title": "Rajdhani Trains",
      "Url": "/api-collection/rajdhani-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Rajdhani Trains   
    {
      "Title": "Shatabdi Trains",
      "Url": "/api-collection/shatabdi-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Shatabdi Trains   
    {
      "Title": "Mail/Express Tains",
      "Url": "/api-collection/mail-express-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Mail/Express Tains   
    {
      "Title": "Janshatabdi Trains",
      "Url": "/api-collection/janshatabdi-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Janshatabdi Trains   
    {
      "Title": "Garib Rath Trains",
      "Url": "/api-collection/garibrath-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Garib Rath Trains   
    {
      "Title": "Premium Trains",
      "Url": "/api-collection/premium-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Premium Trains   
    {
      "Title": "Superfast Trains",
      "Url": "/api-collection/superfast-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Superfast    Trains
    {
      "Title": "Average Delay of Trains",
      "Url": "/api-collection/average-delay-of-train",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Average Delay of Trains   
    {
      "Title": "Station On Map",
      "Url": "/api-collection/station-location-on-map",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Station On Map
    {
      "Title": "Coach Layout",
      "Url": "/api-collection/coach-layout",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Coach Layout
    {
      "Title": "Coach Position",
      "Url": "/api-collection/coach-position",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Coach Position
    {
      "Title": "Tejas Trains",
      "Url": "/api-collection",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Tejas Trains
    {
      "Title": "Hamsafar Trains",
      "Url": "/api-collection",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Hamsafar Trains
    {
      "Title": "Duranto Trains",
      "Url": "/api-collection",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Duranto Trains
    {
      "Title": "Seat Availability",
      "Url": "/api-collection/seat-availability-in-trains",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    }//,//Seat Availability
    // {
    //   "Title": "Premium Trains",
    //   "Url": "/api-collection",
    //   "ImageUrl": "Mike",
    //   "Rating": "5",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // },//Premium Trains
    // {
    //   "Title": "Mike",
    //   "Url": "/api-collection",
    //   "ImageUrl": "Mike",
    //   "Rating": "5",
    //   "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    // }
  ];
}