import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
//import { map } from 'rxjs/operators';

import 'rxjs/add/operator/map';

import { User } from '../dashboard/register';
import { GlobalConstants } from '../common/global-constants';

@Injectable()
export class AuthenticationService {
  Url: string;
  token: string;
  header: any;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.Url = GlobalConstants.apiURL + '/api/Login/';

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();


    const headerSettings: { [name: string]: string | string[]; } = {};
    this.header = new HttpHeaders(headerSettings);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username, password) {
    return this.http.post<any>('${config.apiUrl}/users/authenticate', { username, password })
      .subscribe(user => {

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      });
  }

  mylogin(model: any) {
    debugger;
    var a = this.Url + 'login';
    return this.http.post<any>(this.Url + 'login', model, { headers: this.header });
    //  .subscribe(user => {

    //    alert('XXX---' + JSON.stringify(user));
    //  // store user details and jwt token in local storage to keep user logged in between page refreshes
    //  localStorage.setItem('currentUser', JSON.stringify(user));
    //  this.currentUserSubject.next(user);
    //  return user;
    //});
  }


  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
