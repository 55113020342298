export class Message {
  clientuniqueid: string;
  type: string;
  message: string;
  date: Date;
}

export class LiveUser {
  UniqueID: string;
  EmailID: string;
  UserName: string;
  Platform: string;
}
