import { Component, OnInit } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { Location } from '@angular/common';
import { LoginService } from './services/login.service';
import { GlobalConstants } from './common/global-constants';

import { ChatService } from './chat/chat.service';

declare var $: any;

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html',
    styleUrls: ['app.component.css']
})

export class AppComponent implements OnInit {
  title = GlobalConstants.siteTitle;

  constructor(location: Location, private router: Router, private loginService: LoginService, private authenticationService: AuthenticationService) {
    console.log(GlobalConstants.apiURL);

    if (this.loginService.currentUserValue) {
      if (location.path().includes("login")) { this.router.navigate(['/app/dashboard']); }
    }
  }

    ngOnInit() {
      $.material.init
      console.log(this.title);
    }
}
