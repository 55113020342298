import { Component, OnInit, ElementRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { Observable } from 'rxjs/Rx';
import { BehaviorSubject } from 'rxjs/Rx';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import 'rxjs/add/operator/map';
import { User } from '../dashboard/register';
import { AuthenticationService } from '../services/authentication.service';
import { first } from 'rxjs/operators';

// import { AuthService } from "angularx-social-login";
// import { SocialUser } from "angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { GlobalConstants } from '../common/global-constants';
import { UtilityService } from '../services/utility.service';
import { SweetAlertService } from '../services/sweetalert.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})


@Component({
  //selector: 'app-buttons',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})

export class SiteLoginComponent implements OnInit {
  Url: string;
  token: string;
  header: any;

  test: Date = new Date();
  private toggleButton: any;
  private sidebarVisible: boolean;
  private nativeElement: Node;

  model: any = {};
  errorMessage: string;


  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  ///constructor(private sweetAlert: SweetAlertService, private utility: UtilityService, private socialAuthService: AuthService, private element: ElementRef, private router: Router, private loginService: LoginService,
    constructor(private sweetAlert: SweetAlertService, private utility: UtilityService, 
      private element: ElementRef, private router: Router, private loginService: LoginService,
      private authenticationService: AuthenticationService) {
          
          this.utility.scrollTop();
    
      this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;

    if (this.loginService.currentUserValue) {
      this.router.navigate(['/app/dashboard']);
    }

  }

  

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


  ngOnInit() {
    
      sessionStorage.removeItem('UserName');
      sessionStorage.clear();

      var navbar : HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

      setTimeout(function() {
          // after 1000 ms we add the class animated to the login/register card
          $('.card').removeClass('card-hidden');
      }, 700);
  }

  sidebarToggle() {
      var toggleButton = this.toggleButton;
      var body = document.getElementsByTagName('body')[0];
      var sidebar = document.getElementsByClassName('navbar-collapse')[0];
      if (this.sidebarVisible == false) {
          setTimeout(function() {
              toggleButton.classList.add('toggled');
          }, 500);
          body.classList.add('nav-open');
          this.sidebarVisible = true;
      } else {
          this.toggleButton.classList.remove('toggled');
          this.sidebarVisible = false;
          body.classList.remove('nav-open');
      }
  }

 
  SweetAlertService() {

    this.sweetAlert.ShowMessage();

    // alert('Invalid  Sweet Alert Service!');

  };



  login() {


    // alert('Invalid login!');

    //debugger;
    this.loginService.Login(this.model).pipe(first()).subscribe(
      data => {
        //debugger;
        alert('YYY---' + JSON.stringify(data));
        if (data.status == "Success") {
          //localStorage.setItem('currentUser', JSON.stringify(data));
          ////this.currentUserSubject.next(data);
          this.router.navigate(['/app/dashboard']);
          //debugger;
        }
        else {
          this.errorMessage = data.Message;
        }
      },
      error => {
        this.errorMessage = error.message;
      });
  };


   public socialSignIn(socialPlatform : string) {
    //  let socialPlatformProvider;
    //  if(socialPlatform == "facebook"){
    //    socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    //  }else if(socialPlatform == "google"){
    //    socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    //  }
     
    //  this.socialAuthService.signIn(socialPlatformProvider).then(
    //    (userData) => {
    //      this.loginService.GoogleLogin(userData).pipe(first()).subscribe(
    //        data => {
    //          //debugger;
    //          alert('YYY---' + JSON.stringify(data.emailID));
    //          GlobalConstants.apiURL = data.emailID;
    //           if (data.status == "Success") {
    //            this.router.navigate(['/app/dashboard']);
    //            //debugger;
    //          }
    //          else {
    //            this.errorMessage = data.Message;
    //          }
    //        },
    //        error => {
    //          this.errorMessage = error.message;
    //        });
    //    }
    //  );
   }


}
