export class GlobalConstants {
    public static apiURL: string = "https://localhost:44312";


    
    public static  apiDomainName = "https://domain-name.com";
    public static  apiDomain = "https://indianrailapi.com";
    public static  apiVersion = "v4";
      
    public static siteTitle: string = "This is example of ItSolutionStuff.com";


    //Sample JSON file...
    private static pathUrl  = "./assets/sample/";
    
    public static  urlLiveTrainStatus = GlobalConstants.pathUrl + "live-train-status.json";
    

}
