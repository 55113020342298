import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AdsenseModule } from 'ng2-adsense';

import { HttpClientModule } from '@angular/common/http';

import { LoginService } from './services/login.service';
import { ChatService } from './chat/chat.service';
// import { SocialLoginModule, AuthServiceConfig } from "angular5-social-login";
// import { GoogleLoginProvider, FacebookLoginProvider } from "angular5-social-login";

// import { LoginOpt } from "angularx-social-login";
// import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
// import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";


// const fbLoginOptions: LoginOpt = {
//   scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
//   return_scopes: true,
//   enable_profile_selector: true
// }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

// const googleLoginOptions: LoginOpt = {
//   scope: 'profile email'
// };


// let config = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider("105574014781-86fl60lc7vhc7oio1sj4pv1depin3nuo.apps.googleusercontent.com", googleLoginOptions)
//   },
//   {
//     id: FacebookLoginProvider.PROVIDER_ID,
//     provider: new FacebookLoginProvider("Facebook-App-Id", fbLoginOptions)
//   }
// ]);

// export function provideConfig() {
//   return config;
// }


// export function getAuthServiceConfigs() {
//   let config = new AuthServiceConfig(
//       [
//         {
//           id: FacebookLoginProvider.PROVIDER_ID,
//           provider: new FacebookLoginProvider("Your-Facebook-app-id")
//         },
//         {
//           id: GoogleLoginProvider.PROVIDER_ID,
//           provider: new GoogleLoginProvider("105574014781-86fl60lc7vhc7oio1sj4pv1depin3nuo.apps.googleusercontent.com")
//         },
//       ]
//   );
//   return config;
// }

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';

import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { AuthGuard } from './_helpers/auth.guard';
import { AuthenticationService } from './services/authentication.service';
import { HttpClient } from '@angular/common/http';
import { HomePageComponent } from './api/home-page/home-page.component';
import { LiveStationComponent } from './api/live-station/live-station.component';

import { PageHeaderComponent } from './api/header/page-header.component';
import { PageFooterComponent } from './api/footer/page-footer.component';
import { PageBackgroundComponent } from './api/page-background/page-background.component';
import { AllApiListComponent } from './api/list/all-api.component';
import { SiteLoginComponent } from './login/login.component';
import { AboutPageComponent } from './about/about.component';
import { FaqPageComponent } from './faq/faq.component';
import { DisclaimerPageComponent } from './disclaimer/disclaimer.component';
import { ContactPageComponent } from './contact/contact.component';
import { PricePageComponent } from './price/price.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { DashboardService } from './services/dashboard.service';
import { ChatPageComponent } from './chat/chat.component';
import { UserListPageComponent } from './online/userlist.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { RefundComponent } from './refund/refund.component';
import { GrievancesComponent } from './grievances/grievances.component';
import { PrivacyAndPolicyComponent } from './privacy-and-policy/privacy-and-policy.component';
import { HomeComponent } from './blogs/home/home.component';
import { TrainsComponent } from './blogs/trains/trains.component';
import { StationsComponent } from './blogs/stations/stations.component';
import { CitiesComponent } from './blogs/cities/cities.component';
import { HistoricalPlacesComponent } from './blogs/historical-places/historical-places.component';
import { ContentSourcesComponent } from './content-sources/content-sources.component';
import { FooterDisclaimerComponent } from './api/footer-disclaimer/footer-disclaimer.component';
import { UpcommingApiComponent } from './api-upcomming/upcomming-api.component';
import { UtilityApiComponent } from './api-utility/utility-api.component';
import { ApiSuspendedComponent } from './api-suspended/api-suspended.component';
import { AdsenseAdSizeComponent } from './adsense/adsense-ad-size.component';
import { LiveTrainStatusComponent } from './api/live-train-status/live-train-status.component';
import { PnrCheckComponent } from './api/pnr-check/pnr-check.component';
import { StationCodeOrNameComponent } from './api/station-code-or-name/station-code-or-name.component';
import { TrainNoInformationComponent } from './api/train-no-information/train-no-information.component';
import { CancelledTrainsListComponent } from './api/cancelled-trains-list/cancelled-trains-list.component';
import { SeatAvailabilityInTrainsComponent } from './api/seat-availability-in-trains/seat-availability-in-trains.component';
import { AutocompleteStationComponent } from './api/autocomplete-station/autocomplete-station.component';
import { StationCodeToNameComponent } from './api/station-code-to-name/station-code-to-name.component';
import { TrainScheduleComponent } from './api/train-schedule/train-schedule.component';
import { AllTrainsOnStationComponent } from './api/all-trains-on-station/all-trains-on-station.component';
import { PartiallyCancelledTrainsListComponent } from './api/partially-cancelled-trains-list/partially-cancelled-trains-list.component';
import { TrainsBetweenStationViaStationComponent } from './api/trains-between-station-via-station/trains-between-station-via-station.component';
import { TrainsBetweenStationComponent } from './api/trains-between-station/trains-between-station.component';
import { CoachPositionComponent } from './api/coach-position/coach-position.component';
import { CoachLayoutComponent } from './api/coach-layout/coach-layout.component';
import { RescheduledTrainsListComponent } from './api/rescheduled-trains-list/rescheduled-trains-list.component';
import { TrainNumberToNameComponent } from './api/train-number-to-name/train-number-to-name.component';
import { StationNameToCodeComponent } from './api/station-name-to-code/station-name-to-code.component';
import { TrainFareComponent } from './api/train-fare/train-fare.component';
import { TrainNameToNumberComponent } from './api/train-name-to-number/train-name-to-number.component';
import { DivertedTrainsListComponent } from './api/diverted-trains-list/diverted-trains-list.component';
import { AutoCompleteTrainInformationComponent } from './api/auto-complete-train-information/auto-complete-train-information.component';
import { StationLocationOnMapComponent } from './api/station-location-on-map/station-location-on-map.component';
import { RajdhaniTrainComponent } from './api/rajdhani-train/rajdhani-train.component';
import { PremiumTrainComponent } from './api/premium-train/premium-train.component';
import { ShatabdiTrainComponent } from './api/shatabdi-train/shatabdi-train.component';
import { GaribrathTrainComponent } from './api/garibrath-train/garibrath-train.component';
import { MailExpressTrainComponent } from './api/mail-express-train/mail-express-train.component';
import { JanshatabdiTrainComponent } from './api/janshatabdi-train/janshatabdi-train.component';
import { SuperfastTrainComponent } from './api/superfast-train/superfast-train.component';
import { FogAffectedTrainComponent } from './api/fog-affected-train/fog-affected-train.component';
import { TrainHistoryComponent } from './api/train-history/train-history.component';
import { HeritageTrainComponent } from './api/heritage-train/heritage-train.component';
import { AverageDelayOfTrainComponent } from './api/average-delay-of-train/average-delay-of-train.component';
import { SpecialTrainComponent } from './api/special-train/special-train.component';
import { CreateComponent } from './support/create/create.component';
import { ViewComponent } from './support/view/view.component';
import { HitCountComponent } from './api/hit-count/hit-count.component';
import { GenerateComponent } from './invoice/generate/generate.component';
import { SweetAlertComponent } from './components/sweetalert/sweetalert.component';



//import { CarouselModule } from 'ngx-owl-carousel-o';
//import { CarouselHolderComponent } from './carousel/carousel-holder.component';

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
  ],
  declarations: [HomeComponent]
})
export class MaterialModule { }

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    AdsenseModule.forRoot({
      adClient: 'ca-pub-7709184991151912',        //sujeetkumarsingh.india.bihar@gmail.com
      adSlot: 6072049795                          //Responsive Ad-Unit,
    }),
    HttpModule,
    MaterialModule,
    MatNativeDateModule,
    SidebarModule,
    NavbarModule,
    FooterModule, HttpClientModule//, CarouselModule , SocialLoginModule
  ],
  declarations: [RefundComponent, AdsenseAdSizeComponent, GrievancesComponent, PrivacyAndPolicyComponent, ContentSourcesComponent, 
    TermsandconditionsComponent, AppComponent, AdminLayoutComponent, HomePageComponent, FooterDisclaimerComponent,
    AuthLayoutComponent, AllApiListComponent, LiveStationComponent, TrainsComponent, StationsComponent, CitiesComponent, HistoricalPlacesComponent,
    UpcommingApiComponent, UtilityApiComponent, ApiSuspendedComponent,// SweetAlertComponent,
    PageHeaderComponent, PageFooterComponent, PageBackgroundComponent,//, CarouselHolderComponent 
    SiteLoginComponent, AboutPageComponent, FaqPageComponent, DisclaimerPageComponent, ChatPageComponent,
    ContactPageComponent, PricePageComponent, UserListPageComponent, LiveTrainStatusComponent, PnrCheckComponent, StationCodeOrNameComponent, TrainNoInformationComponent, CancelledTrainsListComponent, SeatAvailabilityInTrainsComponent, AutocompleteStationComponent, StationCodeToNameComponent, TrainScheduleComponent, AllTrainsOnStationComponent, PartiallyCancelledTrainsListComponent, TrainsBetweenStationViaStationComponent, TrainsBetweenStationComponent, CoachPositionComponent, CoachLayoutComponent, RescheduledTrainsListComponent, TrainNumberToNameComponent, StationNameToCodeComponent, TrainFareComponent, TrainNameToNumberComponent, DivertedTrainsListComponent, AutoCompleteTrainInformationComponent, StationLocationOnMapComponent, RajdhaniTrainComponent, PremiumTrainComponent, ShatabdiTrainComponent, GaribrathTrainComponent, MailExpressTrainComponent, JanshatabdiTrainComponent, SuperfastTrainComponent, FogAffectedTrainComponent, TrainHistoryComponent, HeritageTrainComponent, AverageDelayOfTrainComponent, SpecialTrainComponent//, ButtonsComponent 
  ],

  providers: [ChatService, AuthGuard, AuthenticationService, LoginService, HttpClient, DashboardService,
    //{
    //provide: AuthServiceConfig,
    //useFactory: provideConfig //,  getAuthServiceConfigs
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
