import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-privacy-and-policy',
  templateUrl: './privacy-and-policy.component.html',
  styleUrls: ['./privacy-and-policy.component.css']
})
export class PrivacyAndPolicyComponent implements OnInit {

  
 
  constructor(private utility: UtilityService) {
    this.utility.scrollTop();
}


  ngOnInit() {
  }

}
