import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { ApiService } from 'src/app/services/apiservice';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-diverted-trains-list',
  templateUrl: './diverted-trains-list.component.html',
  styleUrls: ['./diverted-trains-list.component.css']
})
export class DivertedTrainsListComponent implements OnInit {

  
    
  
    
  public obj : string = '';
  public strJSON : any = JSON.stringify(this.obj);

  
  constructor(private utility: UtilityService, private api: ApiService) {
    this.utility.scrollTop();
    
    this.BindData();
}



BindData(){
  this.api.getListOfGroup(GlobalConstants.urlLiveTrainStatus).subscribe(
    data => {
      this.obj = JSON.stringify(data);
      console.log(data);
    }, err => { console.log(err); }
  );
}









  ngOnInit() {
  }

}
