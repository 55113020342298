import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { from  } from 'rxjs';
import { Register, User } from "../dashboard/register";

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';



//@Injectable({ providedIn: 'root' })

@Injectable()
export class DashboardService {
  Url: string;
  token: string;
  header: any;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;


    constructor(private http: HttpClient) {
      this.Url = '/api/Dashboard/';
      //const headerSettings: { [name: string]: string | string[]; } = {};
      //this.header = new HttpHeaders(headerSettings);


      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUser = this.currentUserSubject.asObservable();
    }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  ApiKey(token) {
    var model = { NewPassword: 'YXYX', Token: token };
    return this.http.post<any>(this.Url + 'userprofile', model, { headers: this.header });
  }

  ApiKeyXXX(token) {
    var model = { NewPassword: 'YXYX', Token: token };

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<any>(this.Url + 'changepassowrd', model, { headers: this.header });

  }


  RegenerateApiKey(token) {
    var model = { NewPassword: 'YXYX', TokenString: token };
    return this.http.post<any>(this.Url + 'generatekey', model, { headers: this.header });
  }


}


