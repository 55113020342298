import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { first } from 'rxjs/operators';

import { Register, User } from "../../dashboard/register";
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LoginService } from '../../services/login.service';

@Component({
    selector: 'app-nav-header',
  templateUrl: 'page-header.component.html'
})

export class PageHeaderComponent implements OnInit {
  UserName: string = "";
  IsLogin: boolean = false;
  //currentUserSubject: any;
  user: any;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  
  //constructor(private http: HttpClient) {
    
  //}

  constructor(private router: Router, private loginService: LoginService, private authenticationService: AuthenticationService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    if (this.loginService.currentUserValue) {
      this.IsLogin = true;
      this.UserName = "XXX";
    }
  }
  
    handleClick(link) {
      this.router.navigate([link]); 
      console.log('Click!', event)
    }


  logout() {
    
    this.loginService.logout();

    this.router.navigate(['/login']); 
  }


    ngOnInit() {
        //throw new Error("Method not implemented.");
    }
}
