import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { Message, LiveUser } from '../chat/chatmessage';

@Injectable()
export class ChatService {
  messageReceived = new EventEmitter<Message>();
  connectionEstablished = new EventEmitter<Boolean>();
  liveUserList = new EventEmitter<LiveUser>();

  allLiveUser_service = new Array<LiveUser>();

  private connectionIsEstablished = false;
  private _hubConnection: HubConnection;

  constructor() {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
    console.log('after hub connection start');
  }

  sendMessage(message: Message) {
    this._hubConnection.invoke('NewMessage', message);
  }

  getLiveUserList(liveUser: LiveUser) {
    this._hubConnection.invoke('GetAllLiveUserList', liveUser);
  }


  getLiveUserList1() {
    this._hubConnection.invoke('GetAllLiveUserList');

    alert("sss" + JSON.stringify(this.allLiveUser_service));
  }

  getLiveUserList2() {
    this._hubConnection.invoke('GetActiveLiveUserList2');
  }

  removeLiveUserList() {
    this._hubConnection.invoke('RemoveAllLiveUserList');
  }

  getActiveUserList() {



    this._hubConnection.invoke('GetActiveLiveUserList', (data: any) => {
      this.liveUserList.emit(data);
      alert(JSON.stringify(data));
      //alert(JSON.stringify(this.liveUserList));
    });
  }

  private createConnection() {
    //alert(window.location.href);
    this._hubConnection = new HubConnectionBuilder()
      .withUrl('https://localhost:44363/' + 'MessageHub')
      .build();

    console.log('Create connection.');

  }

  private startConnection(): void {
    this._hubConnection
      .start()
      .then(() => {
        this.connectionIsEstablished = true;
        console.log('Hub connection started');
        this.connectionEstablished.emit(true);


        console.log('after hub connection start');
        this.sendUserInformation()
      })
      .catch(err => {
        console.log('Error while establishing connection, retrying...');
        setTimeout(function () { this.startConnection(); }, 5000);
      });
  }

  private registerOnServerEvents(): void {
    this._hubConnection.on('MessageReceived', (data: any) => {
      this.messageReceived.emit(data);
    });


    this._hubConnection.on('GetAllLiveUserList', (data: any) => {
      //alert("1  ::  " + JSON.stringify(data));
      this.allLiveUser_service = data;
      this.liveUserList.emit(data);
    });

    this._hubConnection.on('GetActiveLiveUserList', (data: any) => {
      //alert("data  :1:  " + JSON.stringify(data));
      this.liveUserList.emit(data);
      this.allLiveUser_service = data;
    });

    this._hubConnection.on('GetActiveLiveUserList2', (data: any) => {
      alert("data  :2:  " + JSON.stringify(data));
      this.allLiveUser_service = data;
      this.liveUserList.emit(data);
    });

    this._hubConnection.on('RemoveAllLiveUserList', (data: any) => {
      alert("RemoveAllLiveUserList  :2:  " + JSON.stringify(data));
      this.allLiveUser_service = data;
      this.liveUserList.emit(data);
    });
  }


  liveUser = new LiveUser();
  uniqueID: string = new Date().getTime().toString();
  sendUserInformation(): void {
    this.liveUser = new LiveUser();
    this.liveUser.UniqueID = this.uniqueID;
    this.liveUser.EmailID = "admin@indianrailapi.com";
    this.liveUser.Platform = "WEB";
    this.liveUser.UserName = this.liveUser.EmailID + "-" + this.liveUser.Platform;
    this.getLiveUserList(this.liveUser);
    //this.chatService.getLiveUserList2();
  }

}  
