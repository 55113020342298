import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { ApiService } from 'src/app/services/apiservice';
import { LoginService } from 'src/app/services/login.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-autocomplete-station',
  templateUrl: './autocomplete-station.component.html',
  styleUrls: ['./autocomplete-station.component.css']
})
export class AutocompleteStationComponent implements OnInit {

  public apiDomain : string = '';
  public apiVersion : string = '';
  
  public obj : string = '';
  //public strJSON : any = JSON.stringify(this.obj);

  header: any;

  constructor(private utility: UtilityService, private api: ApiService, private http: HttpClient, private loginService: LoginService) {
    this.utility.scrollTop();
    this.apiDomain = GlobalConstants.apiDomain;
    this.apiVersion = GlobalConstants.apiVersion;

    const headerSettings: { [name: string]: string | string[]; } = {};
    this.header = new HttpHeaders(headerSettings);
    
    this.BindData();
}


BindData(){
  this.api.getListOfGroup(GlobalConstants.urlLiveTrainStatus).subscribe(
    data => {
      this.obj = JSON.stringify(data);
      console.log(data);
    }, err => { console.log(err); }
  );
}



  ngOnInit() {
  }

}
