import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'faq.component.html',
  styleUrls: ['faq.component.css']
})

export class FaqPageComponent {
    
  
  constructor(private router: Router) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  
  faqList = [
    {
      "ID": "1",
      "Title": "Title-1",
      "HeaderID": "heading1",
      "ContentID": "body1",
      "Content": "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
      "IsOpen": ""
    },
    {
      "ID": "2",
      "Title": "Title-2",
      "HeaderID": "heading2",
      "ContentID": "body2",
      "Content": "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
      "IsOpen": ""
    },
    {
      "ID": "3",
      "Title": "Title-3",
      "HeaderID": "heading3",
      "ContentID": "body3",
      "Content": "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
      "IsOpen": ""
    },
    {
      "ID": "4",
      "Title": "Title-4",
      "HeaderID": "heading4",
      "ContentID": "body4",
      "Content": "Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. Raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.",
      "IsOpen": ""
    }];


    
  }