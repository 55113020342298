import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-grievances',
  templateUrl: './grievances.component.html',
  styleUrls: ['./grievances.component.css']
})
export class GrievancesComponent implements OnInit {

  
 
  constructor(private utility: UtilityService) {
    this.utility.scrollTop();
}


  ngOnInit() {
  }

}
