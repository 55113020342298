import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-api-suspended',
  templateUrl: './api-suspended.component.html',
  styleUrls: ['./api-suspended.component.css']
})
export class ApiSuspendedComponent implements OnInit {

  constructor(private router: Router) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  apiList = [
    {
      "Title": "Live Station",
      "Url": "/api-collection/live-station",
      "ImageUrl": "/assets/img/card-2.jpeg",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Live Station    
    {
      "Title": "PNR Check",
      "Url": "/api-collection/pnr-check",
      "ImageUrl": "Mike",
      "Rating": "3.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//PNR Check 
    {
      "Title": "Cancelled Trains",
      "Url": "/api-collection/cancelled-trains-list",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Cancelled Trains   
    {
      "Title": "Partially Cancelled Trains",
      "Url": "/api-collection/partially-cancelled-trains-list",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Partially Cancelled Trains   
    {
      "Title": "Rescheduled Trains",
      "Url": "/api-collection/rescheduled-trains-list",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Rescheduled Trains   
    {
      "Title": "Diverted Trains",
      "Url": "/api-collection/diverted-trains-list",
      "ImageUrl": "Mike",
      "Rating": "5",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    }//Diverted Trains 
  ];

  ngOnInit() {
  }

}
