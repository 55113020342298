import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-content-sources',
  templateUrl: './content-sources.component.html',
  styleUrls: ['./content-sources.component.css']
})
export class ContentSourcesComponent implements OnInit {

 
  constructor(private utility: UtilityService) {
    this.utility.scrollTop();
}

  ngOnInit() {
  }

}
