import { Component, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/common/global-constants';
import { ApiService } from 'src/app/services/apiservice';
import { UtilityService } from 'src/app/services/utility.service';

// import { PageHeaderComponent } from '../header/page-header.component';
// import { PageFooterComponent } from '../footer/page-footer.component';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'live-station.component.html',
  styleUrls: ['./live-station.component.css']
})

export class LiveStationComponent {
    
  
    
  public obj : string = '';
  public strJSON : any = JSON.stringify(this.obj);

  
  constructor(private utility: UtilityService, private api: ApiService) {
    this.utility.scrollTop();
    
    this.BindData();
}



BindData(){
  this.api.getListOfGroup(GlobalConstants.urlLiveTrainStatus).subscribe(
    data => {
      this.obj = JSON.stringify(data);
      console.log(data);
    }, err => { console.log(err); }
  );
}







}