import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './_helpers/auth.guard';
//import { LoginComponent } from './pages/login/login.component';
import { LoginComponent } from './pages/login/login.component';
import { HomePageComponent } from './api/home-page/home-page.component';
import { LiveStationComponent } from './api/live-station/live-station.component';
import { AllApiListComponent } from './api/list/all-api.component';
import { SiteLoginComponent } from './login/login.component';
import { FaqPageComponent } from './faq/faq.component';
import { PricePageComponent } from './price/price.component';
import { ContactPageComponent } from './contact/contact.component';
import { AboutPageComponent } from './about/about.component';
import { DisclaimerPageComponent } from './disclaimer/disclaimer.component';
import { ChatPageComponent } from './chat/chat.component';
import { UserListPageComponent } from './online/userlist.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { ContentSourcesComponent } from './content-sources/content-sources.component';
import { GrievancesComponent } from './grievances/grievances.component';
import { PrivacyAndPolicyComponent } from './privacy-and-policy/privacy-and-policy.component';
import { RefundComponent } from './refund/refund.component';
import { UpcommingApiComponent } from './api-upcomming/upcomming-api.component';
import { ApiSuspendedComponent } from './api-suspended/api-suspended.component';
import { UtilityApiComponent } from './api-utility/utility-api.component';
import { AdsenseComponent } from 'ng2-adsense';
import { AdsenseAdSizeComponent } from './adsense/adsense-ad-size.component';
import { LiveTrainStatusComponent } from './api/live-train-status/live-train-status.component';
import { PnrCheckComponent } from './api/pnr-check/pnr-check.component';
import { StationCodeOrNameComponent } from './api/station-code-or-name/station-code-or-name.component';
import { TrainScheduleComponent } from './api/train-schedule/train-schedule.component';
import { CancelledTrainsListComponent } from './api/cancelled-trains-list/cancelled-trains-list.component';
import { SeatAvailabilityInTrainsComponent } from './api/seat-availability-in-trains/seat-availability-in-trains.component';
import { AutocompleteStationComponent } from './api/autocomplete-station/autocomplete-station.component';
import { StationCodeToNameComponent } from './api/station-code-to-name/station-code-to-name.component';
import { AllTrainsOnStationComponent } from './api/all-trains-on-station/all-trains-on-station.component';
import { PartiallyCancelledTrainsListComponent } from './api/partially-cancelled-trains-list/partially-cancelled-trains-list.component';
import { TrainsBetweenStationViaStationComponent } from './api/trains-between-station-via-station/trains-between-station-via-station.component';
import { CoachPositionComponent } from './api/coach-position/coach-position.component';
import { TrainFareComponent } from './api/train-fare/train-fare.component';
import { StationNameToCodeComponent } from './api/station-name-to-code/station-name-to-code.component';
import { TrainNumberToNameComponent } from './api/train-number-to-name/train-number-to-name.component';
import { RescheduledTrainsListComponent } from './api/rescheduled-trains-list/rescheduled-trains-list.component';
import { TrainsBetweenStationComponent } from './api/trains-between-station/trains-between-station.component';
import { CoachLayoutComponent } from './api/coach-layout/coach-layout.component';
import { TrainNameToNumberComponent } from './api/train-name-to-number/train-name-to-number.component';
import { DivertedTrainsListComponent } from './api/diverted-trains-list/diverted-trains-list.component';
import { AutoCompleteTrainInformationComponent } from './api/auto-complete-train-information/auto-complete-train-information.component';
import { StationLocationOnMapComponent } from './api/station-location-on-map/station-location-on-map.component';
import { SpecialTrainComponent } from './api/special-train/special-train.component';
import { AverageDelayOfTrainComponent } from './api/average-delay-of-train/average-delay-of-train.component';
import { HeritageTrainComponent } from './api/heritage-train/heritage-train.component';
import { TrainHistoryComponent } from './api/train-history/train-history.component';
import { FogAffectedTrainComponent } from './api/fog-affected-train/fog-affected-train.component';
import { SuperfastTrainComponent } from './api/superfast-train/superfast-train.component';
import { RajdhaniTrainComponent } from './api/rajdhani-train/rajdhani-train.component';
import { PremiumTrainComponent } from './api/premium-train/premium-train.component';
import { ShatabdiTrainComponent } from './api/shatabdi-train/shatabdi-train.component';
import { GaribrathTrainComponent } from './api/garibrath-train/garibrath-train.component';
import { MailExpressTrainComponent } from './api/mail-express-train/mail-express-train.component';
import { JanshatabdiTrainComponent } from './api/janshatabdi-train/janshatabdi-train.component';
import { SweetAlertComponent } from './components/sweetalert/sweetalert.component';
import { TrainNoInformationComponent } from './api/train-no-information/train-no-information.component';

export const AppRoutes: Routes = [

  {
    path: '',
    component: HomePageComponent
  },//''
  {
    path: 'login',    
    // component: SweetAlertComponent
    component: SiteLoginComponent
  },//login
  {
    path: 'disclaimer',
    component: DisclaimerPageComponent
  },//disclaimer
  {
    path: 'about',
    component: AboutPageComponent
  },//about
  {
    path: 'chat2',
    component: ChatPageComponent
  },//chat2
  {
    path: 'user-list',
    component: UserListPageComponent
  },//user-list
  {
    path: 'developers',
    component: AboutPageComponent
  },//developers
  {
    path: 'adsense-ad-size',
    component: AdsenseAdSizeComponent
  },//adsense-ad-size
  {
    path: 'forgot-password',
    component: AboutPageComponent
  },//forgot-password
  {
    path: 'contact-us',
    component: ContactPageComponent
  },//contact-us
  {
    path: 'report-on-errors',
    component: ContactPageComponent
  },//report-on-errors
  {
    path: 'price',
    component: PricePageComponent
  },//price
  {
    path: 'email-verification',
    component: PricePageComponent
  },//email-verification
  {
    path: 'terms-and-conditions',
    component: TermsandconditionsComponent
  },//terms-and-conditions
  {
    path: 'privacy-and-policy',
    component: PrivacyAndPolicyComponent
  },//privacy-and-policy
  {
    path: 'refund',
    component: RefundComponent
  },//refund
  {
    path: 'grievance',
    component: GrievancesComponent
  },//grievance
  {
    path: 'source-of-content',
    component: ContentSourcesComponent
  },//source-of-content
  {
    path: 'frequently-asked-question',
    component: FaqPageComponent
  },//frequently-asked-question
  {
    path: 'upcoming-api',
    component: UpcommingApiComponent
  },//upcoming-api
  {
    path: 'suspended-api',
    component: ApiSuspendedComponent
  },//suspended-api
  {
    path: 'utility-api',
    component: UtilityApiComponent
  },//utility-api
  {
    path: 'api-collection',
    component: AllApiListComponent
  },//api-collection
  {
    path: 'api-collection/live-station',
    component: LiveStationComponent
  },//live-station
  {
    path: 'api-collection/live-train-status',
    component: LiveTrainStatusComponent
  },//live-train-status
  {
    path: 'api-collection/pnr-check',
    component: PnrCheckComponent
  },//pnr-check
  {
    path: 'api-collection/station-code-or-name',
    component: StationCodeOrNameComponent
  },//station-code-or-name
  {
    path: 'api-collection/train-no-information',
    component: TrainNoInformationComponent
  },//train-no-information
  {
    path: 'api-collection/cancelled-trains-list',
    component: CancelledTrainsListComponent
  },//cancelled-trains-list
  {
    path: 'api-collection/seat-availability-in-trains',
    component: SeatAvailabilityInTrainsComponent
  },//seat-availability-in-trains
  {
    path: 'api-collection/autocomplete-station',
    component: AutocompleteStationComponent
  },//autocomplete-station
  {
    path: 'api-collection/station-code-to-name',
    component: StationCodeToNameComponent
  },//station-code-to-name
  {
    path: 'api-collection/train-schedule',
    component: TrainScheduleComponent
  },//train-schedule
  {
    path: 'api-collection/all-trains-on-station',
    component: AllTrainsOnStationComponent
  },//all-trains-on-station
  {
    path: 'api-collection/partially-cancelled-trains-list',
    component: PartiallyCancelledTrainsListComponent
  },//partially-cancelled-trains-list
  {
    path: 'api-collection/trains-between-station-via-station',
    component: TrainsBetweenStationViaStationComponent
  },//trains-between-station-via-station
  {
    path: 'api-collection/coach-position',
    component: CoachPositionComponent
  },//coach-position
  {
    path: 'api-collection/train-fare',
    component: TrainFareComponent
  },//train-fare
  {
    path: 'api-collection/station-name-to-code',
    component: StationNameToCodeComponent
  },//station-name-to-code
  {
    path: 'api-collection/train-number-to-name',
    component: TrainNumberToNameComponent
  },//train-number-to-name
  {
    path: 'api-collection/rescheduled-trains-list',
    component: RescheduledTrainsListComponent
  },//rescheduled-trains-list
  {
    path: 'api-collection/trains-between-station',
    component: TrainsBetweenStationComponent
  },//trains-between-station
  {
    path: 'api-collection/coach-layout',
    component: CoachLayoutComponent
  },//coach-layout
  {
    path: 'api-collection/train-name-to-number',
    component: TrainNameToNumberComponent
  },//train-name-to-number
  {
    path: 'api-collection/diverted-trains-list',
    component: DivertedTrainsListComponent
  },//diverted-trains-list
  {
    path: 'api-collection/auto-complete-train-information',
    component: AutoCompleteTrainInformationComponent
  },//auto-complete-train-information
  {
    path: 'api-collection/station-location-on-map',
    component: StationLocationOnMapComponent
  },//station-location-on-map
  {
    path: 'api-collection/special-train',
    component: SpecialTrainComponent
  },//special-train
  {
    path: 'api-collection/average-delay-of-train',
    component: AverageDelayOfTrainComponent
  },//average-delay-of-train
  {
    path: 'api-collection/heritage-train',
    component: HeritageTrainComponent
  },//heritage-train
  {
    path: 'api-collection/train-history',
    component: TrainHistoryComponent
  },//train-history
  {
    path: 'api-collection/fog-affected-train',
    component: FogAffectedTrainComponent
  },//fog-affected-train
  {
    path: 'api-collection/superfast-train',
    component: SuperfastTrainComponent
  },//superfast-train
  {
    path: 'api-collection/rajdhani-train',
    component: RajdhaniTrainComponent
  },//rajdhani-train
  {
    path: 'api-collection/premium-train',
    component: PremiumTrainComponent
  },//premium-train
  {
    path: 'api-collection/shatabdi-train',
    component: ShatabdiTrainComponent
  },//shatabdi-train
  {
    path: 'api-collection/garibrath-train',
    component: GaribrathTrainComponent
  },//garibrath-train
  {
    path: 'api-collection/mail-express-train',
    component: MailExpressTrainComponent
  },//mail-express-train
  {
    path: 'api-collection/janshatabdi-train',
    component: JanshatabdiTrainComponent
  },//janshatabdi-train
  {
    path: 'api-collection/train-number-to-name',
    component: TrainNumberToNameComponent
  },//train-number-to-name
  {
    path: '',
    redirectTo: 'app/dashboard',
    pathMatch: 'full',
  },//dashboard
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '', canActivate: [AuthGuard],
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'components', //canActivate: [AuthGuard],
        loadChildren: './components/components.module#ComponentsModule'
      },//components
      {
        path: 'forms', canActivate: [AuthGuard],
        loadChildren: './forms/forms.module#Forms'
      },//forms
      {
        path: 'tables', canActivate: [AuthGuard],
        loadChildren: './tables/tables.module#TablesModule'
      },//tables
      {
        path: 'maps', canActivate: [AuthGuard],
        loadChildren: './maps/maps.module#MapsModule'
      },//maps
      {
        path: 'widgets', canActivate: [AuthGuard],
        loadChildren: './widgets/widgets.module#WidgetsModule'
      },//widgets
      {
        path: 'charts', canActivate: [AuthGuard],
        loadChildren: './charts/charts.module#ChartsModule'
      },//charts
      {
        path: 'calendar', canActivate: [AuthGuard],
        loadChildren: './calendar/calendar.module#CalendarModule'
      },//calendar
      // {
      //   path: '',
      //   loadChildren: './userpage/user.module#UserModule'
      // },
      {
        path: 'temp',
        loadChildren: './timeline/timeline.module#TimelineModule'
      }//temp
    ]
  },
  // {
  //     path: '',
  //     component: AuthLayoutComponent,
  //     children: [
  //         {
  //             path: 'api-collection',
  //             loadChildren: './api/api.module#ApiPagesModule'
  //         }
  //     ]
  // },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'pages', canActivate: [AuthGuard],
      loadChildren: './pages/pages.module#PagesModule'
    }]
  }
];
