import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-utility-api',
  templateUrl: './utility-api.component.html',
  styleUrls: ['./utility-api.component.css']
})
export class UtilityApiComponent implements OnInit {

  
  constructor(private router: Router) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  apiList = [
    {
      "Title": "Countries",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "4+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Countries   
    {
      "Title": "Demographic",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "3.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Demographic   
    {
      "Title": "Pin Code",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "3.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Pin Code   
    {
      "Title": "Petrol Price [IN]",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "3.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Petrol(India)   
    {
      "Title": "Currency Exchange",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "3.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//Currency   
    {
      "Title": "USD-INR",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "3.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//USD-INR    
    {
      "Title": "EURO-INR",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "3.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    },//EURO-INR   
    {
      "Title": "IFS Code",
      "Url": "/live-station",
      "ImageUrl": "Mike",
      "Rating": "3.5+",
      "Images": [{ "ImageUrl": "blue" }, { "ImageUrl": "white" }]
    }//IFSC 
  ];


  ngOnInit() {
  }

}
