import { Component, OnInit, Renderer, ViewChild, ElementRef, Directive } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { GetStreamService } from '../../services/GetStreamService';
import { LoginService } from '../../services/login.service';

import * as stream from 'getstream';
const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.css']
})

export class NavbarComponent implements OnInit {

    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    token: any;
    notifications: any[];
    notificationsShow: any[];
    private notificationsVisible: boolean;
    private showDropdown: boolean;
    @ViewChild('app-navbar-cmp', { static: true }) button: any;

    constructor(private loginService: LoginService, private router: Router, location: Location, private renderer: Renderer, private element: ElementRef,
        public getStreamService: GetStreamService
    ) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
        this.notificationsVisible = false;
        this.notifications = [];
        this.notificationsShow = [];
        this.showDropdown = false;
    }


    handleClick(link) {
        this.router.navigate([link]);
        console.log('Click!', event)
    }


    ngOnInit() {


        this.getStreamService.getToken()
            .subscribe(
                token => {
                    console.log(token);
                    this.token = token;
                    var client = stream.connect('mhvadvrbedsu', null, '35055');
                    var user1 = client.feed('user', '1', this.token);
                    user1.subscribe(this.callback.bind(this)).then(this.successCallback, this.failCallback);
                },
                err => {
                    // Log errors if any
                    console.log(err);
                });

        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        if ($('body').hasClass('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if ($('body').hasClass('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        $('#minimizeSidebar').click(function () {
            if (misc.sidebar_mini_active === true) {
                $('body').removeClass('sidebar-mini');
                misc.sidebar_mini_active = false;

            } else {
                setTimeout(function () {
                    $('body').addClass('sidebar-mini');

                    misc.sidebar_mini_active = true;
                }, 300);
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            const simulateWindowResize = setInterval(function () {
                window.dispatchEvent(new Event('resize'));
            }, 180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function () {
                clearInterval(simulateWindowResize);
            }, 1000);
        });
        $('#hideSidebar').click(function () {
            if (misc.hide_sidebar_active === true) {
                setTimeout(function () {
                    $('body').removeClass('hide-sidebar');
                    misc.hide_sidebar_active = false;
                }, 300);
                setTimeout(function () {
                    $('.sidebar').removeClass('animation');
                }, 600);
                $('.sidebar').addClass('animation');

            } else {
                setTimeout(function () {
                    $('body').addClass('hide-sidebar');
                    // $('.sidebar').addClass('animation');
                    misc.hide_sidebar_active = true;
                }, 300);
            }

            // we simulate the window Resize so the charts will get updated in realtime.
            const simulateWindowResize = setInterval(function () {
                window.dispatchEvent(new Event('resize'));
            }, 180);

            // we stop the simulation of Window Resize after the animations are completed
            setTimeout(function () {
                clearInterval(simulateWindowResize);
            }, 1000);
        });
    }
    onResize(event) {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    }
    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        body.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    getTitle() {
        //let titlee: any = this.location.prepareExternalUrl(this.location.path());
        //for (let i = 0; i < this.listTitles.length; i++) {
        //    if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
        //        return this.listTitles[i].title;
        //    } else if (this.listTitles[i].type === "sub") {
        //        for (let j = 0; j < this.listTitles[i].children.length; j++) {
        //            let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
        //            if (subtitle === titlee) {
        //                return this.listTitles[i].children[j].title;
        //            }
        //        }
        //    }
        //}
        return 'Home';
    }
    getPath() {
        return "/";//this.location.prepareExternalUrl(this.location.path());
    }

    //Notifications callback
    callback(data) {
        console.log(data['new'][0].message);
        console.log(this);
        this.notificationsVisible = true;
        this.notifications.push(data['new'][0].message);
        this.notificationsShow.push(data['new'][0].message);
        console.log(this.notifications);
    }

    successCallback() {
        this.notificationsVisible = true;
        this.notifications = [];
        console.log('now listening to changes in realtime');
    }

    failCallback(data) {
        alert('something went wrong, check the console logs');
        console.log(data);
    }

    markNotificationsAsRead(event) {
        if (this.notificationsVisible == false) {
            this.notifications = [];
        } else {
            this.notificationsVisible = false;
            this.notificationsShow = [];
        }
    }


    logout() {
        this.loginService.logout();
        this.router.navigate(['/login']);
    }


}
