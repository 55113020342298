import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  scrollTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
