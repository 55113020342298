import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-nav-background',
  templateUrl: 'page-background.component.html',
  styleUrls: ['page-background.component.css']
})

export class PageBackgroundComponent {
    
    
}