import { Injectable } from '@angular/core';
//import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {

  constructor() { }

  scrollTop(){
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  
  ShowMessage(){
  //   swal({
  //     title: 'Here is a message!',
  //     text: 'It is pretty, is not it?',
  //     buttonsStyling: false,
  //     confirmButtonClass: 'btn btn-info'
  // }).catch(swal.noop);
  }

}
