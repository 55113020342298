import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility.service';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'about.component.html',
  styleUrls: ['about.component.css']
})

export class AboutPageComponent {
  constructor(private utility: UtilityService) {
    this.utility.scrollTop();
}



}