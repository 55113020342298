import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
//import { from  } from 'rxjs';
import { Register, User } from "../dashboard/register";

import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GlobalConstants } from '../common/global-constants';

@Injectable()
export class LoginService {
  Url: string;
  token: string;
  header: any;

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;


  constructor(private http: HttpClient) {
    this.Url = GlobalConstants.apiURL + '/api/Login/';
    const headerSettings: { [name: string]: string | string[]; } = {};
    this.header = new HttpHeaders(headerSettings);


    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", "Authorization": "c31z" })
    };

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  Login(model: any) {
    //debugger;
    var a = this.Url + 'login';
    //OK    return this.http.post<any>(this.Url + 'login', model, { headers: this.header });

    return this.http.post<any>(this.Url + 'login', model, { headers: this.header })
      .pipe(map(user => {
        alert('X-X-X-000---' + JSON.stringify(user));
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
    //  .subscribe(user => {
    //  alert('XXX---' + JSON.stringify(user));
    //  localStorage.setItem('currentUser', JSON.stringify(user));
    //  this.currentUserSubject.next(user);
    //  return user;
    //});


    //return this.http.get<any>(this.Url + 'UserLogin2', model);
    //return this.http.get<any>(this.Url + 'UserLogin2', { headers: this.header });
  }


  GoogleLogin(model: any) {
    //debugger;
    return this.http.post<any>(this.Url + 'google', model, { headers: this.header })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  CreateUser(register: Register) {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post<Register[]>(this.Url + 'createcontact/', register, httpOptions)
  }

  logout() {

    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

  }

  
  public getJSON(url: string): Observable<any> {
    return this.http.get(url);
}
  
  public getJsonData(url: string): Observable<any> {

      // Call the http GET
      return this.http.get(url).pipe(map(this.extractData),
        catchError(this.handleError)
      );
  }


  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
}


