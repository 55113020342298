import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-nav-footer',
  templateUrl: 'page-footer.component.html'
})

export class PageFooterComponent {
    
  constructor(private router:Router) {
    
}

  handleClick(link) {
    this.router.navigate([link]); 
    console.log('Click!', event)
  }
}