import { Component, OnInit } from '@angular/core';

@Component({
  //selector: 'app-buttons',
  templateUrl: 'price.component.html',
  styleUrls: ['price.component.css']
})

export class PricePageComponent {
    
}