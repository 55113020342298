import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-adsense-ad-size',
  templateUrl: './adsense-ad-size.component.html',
  styleUrls: ['./adsense-ad-size.component.css']
})
export class AdsenseAdSizeComponent implements OnInit {

  
  constructor(private utility: UtilityService) {
    this.utility.scrollTop();
}




  ngOnInit() {
  }

}
